<app-stepper-page-template
  [title]="'tableAndAreas.onboarding.title'"
  [description]="'tableAndAreas.onboarding.stepDescription'"
  [progressTitle]="'tableAndAreas.onboarding.progressTitle'"
  [numberOfSteps]="7"
  [step]="4"
>
  <ul class="list-disc mt-7 pl-5 leading-relaxed">
    <li translate>tableAndAreas.p0</li>
    <li translate>tableAndAreas.p2</li>
    <li translate>tableAndAreas.p3</li>
  </ul>

  <button class="btn btn-outline hasIcon mt-7" (click)="newArea()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="size-6"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M12 4.5v15m7.5-7.5h-15"
      />
    </svg>
    <span translate>tableAndAreas.newArea</span>
  </button>

  <div *ngFor="let area of areas" class="mt-11">
    <div>
      <div>
        <div class="flex flex-row gap-5 items-center">
          <h1>{{ area.name }}</h1>
          <button class="linkButton button" (click)="editArea(area)" translate>
            general.edit
          </button>
          <button
            class="linkButton danger button"
            (click)="deleteArea(area)"
            translate
          >
            general.delete
          </button>
        </div>
        <div>
          <span *ngIf="area.availableOnline" translate
            >tableAndAreas.availableOnline</span
          >
          <span *ngIf="!area.availableOnline" translate
            >tableAndAreas.notAvailableOnline</span
          >
          ·
          <span translate>tableAndAreas.bookingPriority</span>
          <span>:&nbsp;{{ area.bookingPriority }}</span>
        </div>
      </div>
    </div>
    <div class="tables mt-3">
      <app-area-tables-view
        [tables]="area.tables"
        (editTable)="editTable($event, area)"
        (deleteTable)="deleteTable($event, area)"
      >
      </app-area-tables-view>

      <button class="btn btn-secondary hasIcon mt-6" (click)="newTable(area)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>
        <span translate>tableAndAreas.newTable.restaurant.please</span>
      </button>
    </div>
  </div>
  <div class="mt-9 flex flex-row justify-between">
    <button
      (click)="back()"
      class="btn btn-primary btn-outline"
      type="button"
      translate
    >
      general.back
    </button>
    <button (click)="next()" class="btn btn-primary" type="button">
      <span *ngIf="!isLoading" translate>general.next</span>
    </button>
  </div>
</app-stepper-page-template>
